
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import { Tag } from '../../types/workGuide'
import { Info } from '../../types/trainingVideo'

@Component({
  components: { UploadFile }
})
export default class TrainingVideoAdd extends Vue {
  private info: Info = {
    videoName: '',
    videoIntroduction: '',
    videoPicture: null,
    videoFile: null,
    tagList: [],
    isShow: 1
  }

  private rules = {
    videoName: [{ required: true, message: '请输入视频名称', trigger: ['blur', 'change'] }],
    videoIntroduction: [{ required: true, message: '请输入视频简介', trigger: ['change'] }],
    videoPicture: [{ required: true, message: '请上传视频封面', trigger: ['change'] }],
    videoFile: [{ required: true, message: '请上传视频附件', trigger: ['change'] }]
  }

  private tagName = ''

  private tagList: Array<Tag> = []

  private fileData = {
    token: '',
    key: ''
  }

  private addShow = false
  private submitShow = false

  private get title () {
    return this.$route.params.id ? '编辑' : '新增'
  }

  created () {
    this.getTagList()
    this.$route.params.id && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.trainingVideo.videoDetail, {
      videoId: this.$route.params.id
    }).then((res) => {
      this.info = res
    })
  }

  getTagList () {
    this.$axios.get(this.$apis.common.thinktankTag).then(res => {
      this.tagList = res.list
    })
  }

  // 封面上传返回
  onSuccessImg (file: FileInfo) {
    this.info.videoPicture = file
  }

  // 视频上传返回
  onSuccessVideo (file: FileInfo) {
    this.info.videoFile = file
  }

  querySearch (queryString: string, cb: Function) {
    const tagList = this.tagList
    const results = queryString ? tagList.filter(this.createFilter(queryString)) : tagList
    this.addShow = results.length === 0
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createFilter (queryString: string) {
    return (tag: Tag) => {
      return (tag.tagName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
    }
  }

  // 标签选中
  handleSelect (v: Tag) {
    (this.info.tagList as Array<Tag>).push(v)
  }

  // 标签删除
  removeTag (index: number) {
    this.info.tagList.splice(index, 1)
  }

  // 自定义标签添加
  addTag () {
    const index = this.info.tagList.findIndex((item: Tag) => {
      return item.tagName === this.tagName
    })
    if (index === -1) {
      (this.info.tagList as Array<Tag>).push({
        tagId: null,
        tagName: this.tagName
      })
      this.tagName = ''
      this.addShow = false
    } else {
      this.$message({
        message: '标签重复',
        type: 'warning'
      })
    }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.id
          ? this.$apis.trainingVideo.videoUpdate
          : this.$apis.trainingVideo.videoAdd
        this.$axios.post(url, this.info)
          .then(() => {
            this.submitShow = false
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.push({ name: 'trainingVideoList' })
          }).finally(() => {
            this.submitShow = false
          })
      }
    })
  }
}
