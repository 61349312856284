import { render, staticRenderFns } from "./TrainingVideoAdd.vue?vue&type=template&id=ea617e1e&scoped=true"
import script from "./TrainingVideoAdd.vue?vue&type=script&lang=ts"
export * from "./TrainingVideoAdd.vue?vue&type=script&lang=ts"
import style0 from "./TrainingVideoAdd.vue?vue&type=style&index=0&id=ea617e1e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea617e1e",
  null
  
)

export default component.exports